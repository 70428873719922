(<any> $).fn.ajaxUpdate = function(url, data, options) {
    var $this=$(this);

    var options=$.extend({

    }, options);

    $.post(url, data, function(d) {
        $this.replaceWith(d);
        $this.trigger('onload');
    });
};

(<any> $).fn.ajaxLoader = function() {
    var $this=$(this);
    $('<div class="ajaxloader">').hide().fadeIn(100).appendTo($this);
};
