export default class SiteFocus {
	public static isOnFocus($el: JQuery) {
		var wh = $(window).height();
		var wt = $(window).scrollTop();
		var wb = wt + wh;
		var et = $el.offset().top
		var eb = et + $el.height();

		if (et >= wt && et <= wb || eb >= wt && eb <= wb) {
			return true;
		} else {
			return false;
		}
	}

	public static onViewFocus($el: JQuery, callback: any) {
		$el.each(function() {
			var $this = $(this);
			var focused = false;

			if (SiteFocus.isOnFocus($this)) {
				callback($this);
			} else {
				$(window).scroll(() => {
					if (SiteFocus.isOnFocus($this)) {
						if (!focused) {
							callback($this);
						}

						focused = true;
					} else {
						focused = false;
					}
				});
			}
		});
	}
}
