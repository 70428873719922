interface FormTipOptions {
	posx?: string;
	posy?: string;

	autoShow?: boolean;

	pos?: {
		top?: number,
		left?: number
	};

	message?: string;
	cssclass?: string;
	fx?: number;
	fy?: number;
}

export default class FormTip {
	private $el: JQuery;
	private $popup: JQuery;

	private attached: boolean;

	private options: FormTipOptions;

	public constructor($el: JQuery, options?: FormTipOptions) {
		this.$el = $el;

		this.options = $.extend({
			posx: 'right',
			posy: 'top',

			autoShow: true,
			pos: null,
			message: null,
			cssclass: null,
			fx: 0.9,
			fy: 0.3
		}, options);

		this.initHtml();
	}

	private initHtml(): void {
		this.$popup=$('\
			<div class="formtip">\
				<div class="formtip__wrapper">\
					<div class="formtip__close"></div>\
					<div class="formtip__body">\
						<span class="formtip__inner"></span>\
					</div>\
				</div>\
			</div>\
		');

		$('.formtip__wrapper', this.$popup).click((e) => {
			e.stopPropagation();
		});

		$('.formtip__close', this.$popup).click((e) => {
			e.preventDefault();

			this.close();
		});

		if (this.options.cssclass) {
			this.$popup.addClass(this.options.cssclass);
		}

		if (this.options.autoShow) {
			this.show();
		}
	}

	public message(str?: string): void {
		$('.formtip__inner', this.$popup).html(str);
	}

	public close(): void {
		this.$popup.fadeOut(200, () => {
			this.$popup.detach();
			this.attached = false;
		});
	}

	public show(pos?: any): void {
		var $tip = this.$popup;

		this.message(this.options.message);

		$tip.css({
			visiblity: 'hidden',
			top: 0,
			left: 0
		});

		$tip.show();

		if (!this.attached) {
			$('body').append(this.$popup);

			this.attached = true;
		}

		var $wrapper = $('.formtip__wrapper', $tip);

		var width = $wrapper.width();
		var height = $wrapper.height();

		var docwidth = $(document).width();

		if (pos == null) {
			pos = this.options.pos;
		}

		if (pos == null) {
			var fx = this.options.fx;
			var fy = this.options.fy;

			var xx, yy;

			if (this.options.posx=='left') {
				xx = -width*fx;
			} else {
				xx = this.$el.outerWidth() * fx;
			}

			if (this.options.posy == 'bottom') {
				yy = this.$el.outerHeight() + height * fy;
			} else {
				yy = this.$el.outerHeight() * fy;
			}

			pos = {
				top: this.$el.offset().top + yy,
				left: this.$el.offset().left + xx
			}
		}

		$tip.css({
			top: pos.top,
			left: pos.left
		});

		var wrappos = $wrapper.offset();

		if (wrappos.left + width > docwidth) {
			$tip.addClass('formtip--inverted');
		} else {
			$tip.removeClass('formtip--inverted');
		}

		$tip.hide();
		$tip.css('visiblity', '');
		$tip.fadeIn(200);

		$(document).one('click.formTip, keypress.formTip', () => {
			this.close();
		});

		if (!$('.body--lock').length) {
			$('html,body').animate({
				scrollTop: $tip.offset().top - height - 100
			}, 300);
		}
	}
}
