import './utils/ajax';
import Form from '../../modules/core/forms/theme/js/Form';
import Utils from '../../modules/utils/utils-js/theme/js/utils/Utils';
import ObjectFit from '../../modules/utils/utils-js/theme/js/utils/ObjectFit';

declare let Slider;

class Site {
    private static initSlider() {
        const $slider = $('.mainslider');

        const stopallfunc = () => {
            $('video', $slider).each(function() {
                this.pause();
            });
        }

        new Slider($slider, {
            fixed: true,
            max: 1,

            onChange: function (i) {
                const $item = $('.slider__item', $slider).eq(i);
                const $figure = $('figure', $item);

                const src = $item.data('movie');

                stopallfunc();

                if (src != null) {
                    if (!$item.hasClass('slider__item--movie')) {
                        $item.addClass('slider__item--movie');

                        const $video = $('<video loop muted>');
                        $video.data('objectfitaspect', 16/9);
                        $video.addClass('object-fit--cover');

                        $video.on('canplaythrough', function() {
                            $video.appendTo($figure);

                            const video = <HTMLVideoElement> $video[0];
                            video.play();

                            $item.addClass('slider__item--movieinited');

                            ObjectFit.update();

                            setTimeout(function () {
                                ObjectFit.update();
                            }, 1);
                        });

                        $video.append('<source src="' + src + '" type="video/mp4">');
                    } else {
                        const $video = $('video', $item);
                        const video = <HTMLVideoElement> $video[0];

                        if (video) {
                            video.play();
                        }
                    }
                }
            }
        });
    }

    public static onPageHome(): void {
        $(() => {
            this.initSlider();
        });
    }

    public static discover($parent?: JQuery): void {
        Utils.updateElements($parent);
    }

    private static updateFloat() {
        var $header=$('.header');

        var top=$(window).scrollTop();
        var wh=$(window).height();

        $('body').toggleClass('body--float', top > 0);
        $('body').toggleClass('body--no-float', top == 0);
        $('body').toggleClass('body--float-100', top > wh);
        $('body').toggleClass('body--float-ac', top > wh - 130);
    }

    private static setWcagColor(ix?: number) {
        let $body = $('body');

        let $colors = $('.wcagcolor');
        let $this = $colors.eq(ix - 1);

        $body.removeClass('bodywcagcolor bodywcagcolor--1 bodywcagcolor--2 bodywcagcolor--3 bodywcagcolor--4');
        $colors.removeClass('wcagcolor--selected');

        if (ix != null) {
            $this.addClass('wcagcolor--selected');
            $body.addClass('bodywcagcolor bodywcagcolor--' + ix);
        }
    }

    private static setWcagZoom(ix?: number) {
        let $body = $('html');

        let $zooms = $('.wcagzoom');
        let $this = $zooms.eq(ix - 1);

        $body.removeClass('bodywcagzoom bodywcagzoom--1 bodywcagzoom--2 bodywcagzoom--3');
        $zooms.removeClass('wcagzoom--selected');

        if (ix != null) {
            $this.addClass('wcagzoom--selected');
            $body.addClass('bodywcagzoom bodywcagzoom--' + ix);
        }
    }

    private static initWcag(): void {
        let This = this;

		let $wcag = $('.wcag');
		let $wcag_color = $('.wcagcolor', $wcag);

		$('.wcagcolor', $wcag).each((ix, el) => {
			let $el = $(el);

			$el.click((e) => {
				e.preventDefault();

				if ($el.hasClass('wcagcolor--selected')) {
					This.setWcagColor(null);
					localStorage.removeItem('wcagcolor');
				} else {
					This.setWcagColor(ix + 1);
					localStorage.setItem('wcagcolor', (ix + 1).toString());
				}
			});
		});

		$('.wcagzoom', $wcag).each((ix, el) => {
			let $el = $(el);

			$el.click((e) => {
				e.preventDefault();

				if ($el.hasClass('wcagzoom--selected') || ix == 0) {
					This.setWcagZoom(null);
					localStorage.removeItem('wcagzoom');
				} else {
					This.setWcagZoom(ix + 1);
					localStorage.setItem('wcagzoom', (ix + 1).toString());
				}
			});
		});

		let ls_zoom = localStorage.getItem('wcagzoom');
		let ls_color = localStorage.getItem('wcagcolor');

		if (ls_zoom != null) {
			this.setWcagZoom(parseInt(ls_zoom));
		}

		if (ls_color != null) {
			this.setWcagColor(parseInt(ls_color));
		}

		$('.wcagmenu__article').click((e) => {
			let $article = $('a[name="article"]');

			if ($article.length) {
				let $header = $('.header');

				setTimeout(() => {
					Utils.scrollTo($article.offset().top - $header.height() - 10, true);
				}, 10);
			}
		});
    }

    private static initSearch(): void {
        let $header = $('.header');
        let $searchbox = $('.header__searchbox');
        let $button = $('.header__search-button');

        let $input = $('.header__searchbox-input', $searchbox);
        let $submit = $('.header__searchbox-button', $searchbox);

        let openfunc = () => {
            $header.toggleClass('header--searchbox-opened');

            $searchbox.stop(true, false).animate({
                height: 140
            }, 500, () => {

            });

            $input.focus();
            $input.select();
        }

        let closefunc = () => {
            $searchbox.stop(true, false).animate({
                height: 0
            }, 500, () => {
                $header.toggleClass('header--searchbox-opened')
            });
        }

        $button.on('click', () => {
            if ($header.hasClass('header--searchbox-opened')) {
                closefunc();
            } else {
                openfunc();
            }
        });

        $searchbox.keyup((e) => {
            if (e.keyCode == 27) {
                closefunc();
            }
        });

        $submit.add($input).on('blur', (e) => {
            let $target = $(e.relatedTarget);

            if (!$target.is($submit) && !$target.is($input)) {
                closefunc();
            }
        });

        $('.wcagmenu__search').click((e) => {
            e.preventDefault();

            openfunc();
        });
    }

    private static initHtml() {
        var $mainmenu=$('.mainmenu');

        $('.menubutton', $mainmenu).click(function(e) {
            e.preventDefault();

            $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
            $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
        });

        $('.solutions__item').each((ix, i) => {
            let $item = $(i);
            let $content = $('.solutions__content', $item);

            $item.hover(
                () => {
                    $content.stop(true, false).slideDown();
                },

                () => {
                    $content.stop(true, false).slideUp();
                }
            );
        });

        this.initSearch();
        this.initWcag();

        this.discover();
    }

    private static initRwd() {
        var $header = $('.header');
        $header.addClass('header--noanim');

        $(window).resize(Site.updateFloat);
        $(window).scroll(Site.updateFloat);

        Site.updateFloat();

        $header.removeClass('header--noanim');
    }

    public static init() {
        (<any> window).Site = Site;

        Form.initModule();
        ObjectFit.initModule();

        $(document).ready(() => {
            Site.initHtml();
            Site.initRwd();
        });

        $(window).on('load', () => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').trigger('pageloaded');
            }, 1000);
        });
    }
}

Site.init();
