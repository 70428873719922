import FormTip from "./FormTip";

declare var tinymce: any;

interface IFormOptions {
	ajax?: boolean,

	tips?: boolean,
	tipsposx?: string,
	tipsposy?: string,

	submit?: any,
	success?: any,
	presuccess?: any,
	datahandler?: (data?: any) => void
}

export default class Form {
	protected options: IFormOptions;

	protected $uid: JQuery;
	protected $form: JQuery;

	private action: string;

	private sending: boolean;

	public constructor($uid: JQuery, options?: IFormOptions) {
		this.$uid = $uid;

		this.options = $.extend({
			ajax: true,

			tips: true,
			tipsposx: 'right',
			tipsposy: 'top',

			submit: null,
			success: null,
			presuccess: null,
			datahandler: null
		}, options);

		this.initHtml();
	}

	public getElement(): JQuery {
		return this.$uid;
	}

	public getFormElement(): JQuery {
		return this.$form;
	}

	protected initHtml(): void {
		if (this.$uid.prop('tagName').toLowerCase() == 'form') {
			this.$form = this.$uid;
		} else {
			this.$form = $('form', this.$uid);
		}

		let This = this;

		this.getButtons().on('click', function (e) {
			let $button = $(this);

			This.action = $button.data('action');

			if ($button.attr('type') == 'button') {
				This.submit();
			}
		});

		$(':input', this.$form).each(function() {
			let $this = $(this);

			let $input = $this.closest('.input');

			$this.focus(() => {
				$input.addClass('input--focused');
			});

			$this.blur(() => {
				$input.removeClass('input--focused');
			});
		});

		Form.initRadios(this.$uid);

		this.$form.submit(() => {
			return this.submit();
		});

		if (this.options.tips) {
			this.prepareErrorTips();
		}
	}

	private getButtons(): JQuery {
		return $('.submit input', this.$form);
	}

	private prepareErrorTips(): void {
		$('.formtip', this.$form).remove();

		let $msgs = $('.input__error, .form__error', this.$form);

		if ($msgs.length > 0) {
			let $msg = $msgs.first();
			let $parent = $msg.parent();
			let $input = $(':input', $parent).first();

			new FormTip($parent, {
				posx: this.options.tipsposx,
				posy: this.options.tipsposy,

				message: $msg.html()
			});

			$input.focus().select();
		}

		$msgs.detach();
	}

	private getVars(): any {
		let $inputs = $('input, select, textarea', this.$form);
		let $result = $();

		$inputs.each((i, el) => {
			let $input = $(el);

			if (!$input.parentsUntil('form, .input').parent().is('.hint')) {
				$result = $result.add($input);
			}
		});

		return $result.serializeArray();
	}

	private setFormAction(): void {
		let action = this.action;

		if (!action) {
			let $submit = $('.submit input[type="submit"]', this.$form);

			action = $submit.data('action');
		}

		let $action = $('input[name="action"]', this.$form);

		if ($action.length == 0 && action != null) {
			$action = $('<input type="hidden" name="action">').prependTo(this.$form);
		}

		$action.val(action);
	}

	public submit(): boolean {
		if (this.sending) {
			return false;
		}

		this.sending = true;

		this.setFormAction();

		if (this.options.ajax) {
			// this.$form._trigger('submit');

			this.$uid.addClass('forms--loading');

			let $buttons = this.getButtons();

			$buttons.attr('disabled', 'disabled');
			$buttons.addClass('button--loading')

			if (typeof tinymce != 'undefined') {
				tinymce.triggerSave();
			}

			$.ajax({
				url: this.$form.attr('action'),
				cache: false,

				method: 'post',
				data: this.getVars(),

				success: (data, text, xhr) => {
					// this._trigger('presuccess');
					$buttons.removeAttr('disabled');
					this.$uid.removeClass('forms--loading');
					$buttons.removeClass('button--loading');

					let $data = null;

					if (this.options.datahandler) {
						this.options.datahandler(data);
					} else {
						var ct = xhr.getResponseHeader('content-type');

						if (ct.indexOf('javascript') == -1) {
							$data=$(data);
							this.$uid.replaceWith($data);
						}
					}

					this.sending = false;
					this.action = null;

					// this._trigger('success', this, $data);
				},

				error: function() {
					$buttons.removeAttr('disabled');
					this.element.removeClass('forms--loading');
					$buttons.removeClass('button--loading');

					this.sending = false;
					this.action = null;

					// this._trigger('error');
				}
			});

			return false;
		} else {
			return true;
		}
	}

	public static initRadios($parent?: JQuery) {
		let $items = $('.radio-item input', $parent);

		function updateAll() {
			$items.each((i, el) => {
				let $el = $(el);
				let $parent = $el.closest('.radio-item');

				$parent.toggleClass('radio-item--selected', $el.is(':checked'));
			});
		}

		$items.change(updateAll);

		updateAll();
	}

	public static initModule(): void {
		(<any> $).fn.form = function (options?: any) {
			new Form($(this), options);
		}

		let _fix1 = window;

		(<any> window).Form = Form;
	}
}
